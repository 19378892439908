import React from 'react'
import MainLayout from '../layouts/MainLayout'
import EosView from '../views/EosView'

const EosPage = () => {
  return (
    <MainLayout className="eos">
      <EosView />
    </MainLayout>
  )
}

export default EosPage
