import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import EosPage from '../ui/pages/EosPage'

const Eos = props => (
  <>
    <HelmetComponent
      title={metaTags.eosTitle}
      description={metaTags.index}
      page="eos"
    />
    <AppWrapper {...props}>
      <EosPage />
    </AppWrapper>
  </>
)

export default Eos
