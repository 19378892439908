import React from 'react'
import smImage from '../../assets/images/hero-eos-sm.png'
import mdImage from '../../assets/images/hero-eos-md.png'
import lgImage from '../../assets/images/hero-eos.png'
import CaseStudyView from './CaseStudyView'
import logo from '../../assets/images/brands/eos-white.png'

const eosItems = [
  { label: 'case-study:sector', value: 'case-study:eos:sector' },
  { label: 'case-study:revenue', value: '€360M+' },
  { label: 'case-study:employees', value: '1,300' },
]

const EosView = () => {
  return (
    <>
      <CaseStudyView
        caseStudy="eos"
        title="case-study:eos:title"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        logo={logo}
        alt="eos"
        items={eosItems}
        percent="20%"
        percentText="case-study:eos:percent-text"
        descriptionTitle="our-approach:title"
        descriptionChallenge="case-study:eos:challenge:description"
        descriptionSolution="case-study:eos:solution:description"
        descriptionOutcome="case-study:eos:outcome:description"
      />
    </>
  )
}

export default EosView
